.title{
    display: flex;
    justify-content: center;
}

.label-style{
    font-weight: bold;
}


.create-data-container{
    padding: 40px;
}
