.logo-header{
    width:60%;
    padding: 2px;
}

.preview-img{
    min-width: 15%;
    max-height: 200px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 156px;
}

.searchbar-background{
    background-color: var(--ion-color-primary);
}

.searchbar{
    text-color: black;
}
.buttons-container{
    margin: auto;
    direction: rtl;
}

.home-background{
    background-color: white !important;
    height: 100%;
}
.card-image{
    width: 100%;
}

.searchbar-container{
    margin: auto;

}

.select-background{
    background-color: white;
    border: outset;
    color: black;
}

.audio-button{
    width: 160px;
}

.related-items{
    font-size: 20px;
}
em{
    font-weight: bold;
}
.content-container{
    display: flex;
}

.highlighted{
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    margin-left: 10px;
}
.no-results{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    margin-top: 20px;
}

.admin-content-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
