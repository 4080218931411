.about-background{
    background-color: #f9d96766 !important;
}

.fondo-about {
    background-size: 100% 100%;
    position: absolute;
}

.title{
    display: flex;
    justify-content: center;
}

.team-title{
    color: var(--ion-color-primary) ;
    font-weight: bold;
    animation: fadeIn 1s forwards;
    animation-delay: 0.75s;
    opacity: 0;

}



.item{
    margin-bottom: 25px;
}

.about-data-container{
    padding: 40px;
    position: absolute;
 }

.about-info-style{
    font-size: 20px;
    padding: 20px;
    margin-top: 2vh;
    animation: fadeIn 1s forwards;
    opacity: 0;
    color: var(--ion-color-primary);
    text-align: justify;
}
.about-info-style2{
    font-size: 20px;
    padding: 20px;
    margin-top: 2vh;
    animation: fadeIn 1s forwards;
    animation-delay: 0.5s;
    opacity: 0;
    color: var(--ion-color-primary);
    text-align: justify;
}
.about-info-style3{
    font-size: 20px;
    padding: 20px;
    margin-top: 2vh;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
    opacity: 0;
    color: var(--ion-color-primary);
    text-align: justify;
}

.team-name{
    font-size: 18px;
    font-weight: bold;
    animation: fadeIn 1s forwards;
    animation-delay: 0.75s;
    opacity: 0;
    color: var(--ion-color-primary);
    text-align: justify;
}

.team-explanation{
    margin-top: 2vh;
    font-size: 15px;
    animation: fadeIn 1s forwards;
    animation-delay: 0.75s;
    opacity: 0;
    color: var(--ion-color-primary);
    text-align: justify;
}


.diamante{
    width: 20px;
    margin-right: 2px;
    animation: rotateIn 1s infinite;
}

.team-foto{
    border-radius: 200px;
}


.rotateIn {
    animation-name: rotateIn;
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes rotateIn {
    0% {
        transform-origin: center;
        transform: rotate3d(0, 0, 1, -200deg);
        opacity: 0;
    }
    100% {
        transform-origin: center;
        transform: none;
        opacity: 1;
    }
}


.fadeIn {
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}
