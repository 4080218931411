.login-card{
    width: 400px;
    min-height: 400px;
    padding: 10px;

}

.login-logo{
    width: 300px;
    margin: auto;
    padding: 15px;
}

.background{
    background-color: var(--ion-color-primary);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-button{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.forgot-container{
    margin-top: 20px;
    display: flex;
    justify-content: right;

}
