
.fondo-description {
    background-size: 100% 100%;
    position: absolute;
}

.description-text-container {
    margin: auto;
    max-width: 70vw;
    padding: 5px;
}

.description-info-style {
    opacity: 0;
    font-size: 1.2vw;
    text-align: justify;
    margin-top: 3vh;
    margin-left: 2vh;
    animation: aparecer 1s forwards;
    animation-delay: 1s;
    color: var(--ion-color-primary);
}

.description-info-style1 {
    opacity: 0;
    font-size: 1.2vw;
    text-align: justify;
    margin-top: 3vh;
    margin-left: 2vh;
    animation: aparecer 1s forwards;
    animation-delay: 1.5s;
    color: var(--ion-color-primary);
}

.description-info-style2 {
    opacity: 0;
    font-size: 1.2vw;
    text-align: justify;
    margin-top: 3vh;
    margin-left: 2vh;
    animation: aparecer 1s forwards;
    animation-delay: 1.75s;
    color: var(--ion-color-primary);
}

.description-info-style3 {
opacity: 0;
    font-size: 1.2vw;
    text-align: justify;
    margin-top: 3vh;
    margin-left: 2vh;
    animation: aparecer 1s forwards;
    animation-delay: 2s;
    color: var(--ion-color-primary);

}

.description-info-style4 {
opacity: 0;
    font-size: 1.2vw;
    text-align: justify;
    margin-top: 3vh;
    margin-left: 2vh;

    animation: aparecer 1s forwards;
    animation-delay: 2.25s;
    color: var(--ion-color-primary);
}


.description-image {
    width: 50%;
    text-align: center;
    animation: pulse 1s infinite;

}

.description-image-container {
    display: flex;
    justify-content: center;
}


.aparecer {
    animation-name: aparecer;
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes aparecer {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


.pulse {

    animation-name: pulse;
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes pulse {
    0% {
        transform: scale3d(1, 1, 1);
    }
    50% {
        transform: scale3d(1.05, 1.05, 1.05);
    }
    100% {
        transform: scale3d(1, 1, 1);
    }
}
