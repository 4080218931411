.label-style {
    width: 150px;
    min-width: 150px;
    padding-bottom: 20px;
}

.term-style{
    font-size: 15px;
    font-weight: normal;
    color: black;
    padding-left: 5px;
}
.term-name-style{
    font-size: 20px;
    margin-bottom: 20px;
}

.data-container{
    padding-bottom: 10px;
}

.info-style{
    font-size: 15px;
}
