
.fondo-press {
    background-size: 100% 100%;
    position: absolute;
}

.press-text-container {
    margin: auto;
    max-width: 70vw;
    padding: 5px;
}

.press-info-style {
    font-size: 1.2vw;
    text-align: justify;
    margin-top: 3vh;
    margin-left: 2vh;
    color: var(--ion-color-primary);
}

.press-info-style1-title {
    font-size: 2vw;
    text-align: justify;
    margin-top: 3vh;
    margin-left: 2vh;
    color: var(--ion-color-primary);
}
.press-info-style1-subtitle {
    font-size: 1.7vw;
    text-align: justify;
    margin-top: 3vh;
    margin-left: 2vh;
    color: var(--ion-color-primary);
    opacity: 1;
}
.press-info-style2-subtitle {
    font-size: 1.5vw;
    text-align: justify;
    margin-top: 3vh;
    margin-left: 2vh;
    color: var(--ion-color-primary);
    opacity: 1;
}
.press-info-style1 {
    opacity: 1;
    font-size: 1.4vw;
    text-align: justify;
    margin-top: 3vh;
    margin-left: 2vh;
}

.press-info-style2-title {
    font-size: 2vw;
    text-align: justify;
    margin-top: 3vh;
    margin-left: 2vh;
    color: var(--ion-color-primary);
}

.press-info-style3-title {
    font-size: 2vw;
    text-align: justify;
    margin-top: 3vh;
    margin-left: 2vh;

    color: var(--ion-color-primary);

}

.press-info-style4 {

    font-size: 1.2vw;
    text-align: justify;
    margin-top: 3vh;
    margin-left: 2vh;



    color: var(--ion-color-primary);
}


.press-image {
    width: 50%;
    text-align: center;



}

.press-image-container {
    display: flex;
    justify-content: center;
}


.aparecer {
    animation-name: aparecer;
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes aparecer {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


.pulse {

    animation-name: pulse;
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes pulse {
    0% {
        transform: scale3d(1, 1, 1);
    }
    50% {
        transform: scale3d(1.05, 1.05, 1.05);
    }
    100% {
        transform: scale3d(1, 1, 1);
    }
}
