.contact-background{
    background-color: #f9d96766 !important;
}

.title{
    display: flex;
    justify-content: center;
}



.item{
    margin-bottom: 25px;
}

.contact-data-container{
    padding: 40px;
 }

.contact-info-style{
    font-size: 20px;
    padding: 20px;
}

.icon-size{
    width: 2vw;
    height: 2vw;
}

.icon-size:hover{
    color: var(--ion-color-secondary);
    width: 2vw;
    height: 2vw;
}

