.recipients-background{
    background-color: #f9d96766 !important;
}

.title{
    display: flex;
    justify-content: center;
}



.item{
    margin-bottom: 25px;
}

.recipients-data-container{
    padding: 40px;
 }

.recipients-info-style{
    font-size: 20px;
    padding: 20px;
}
