.logo-home-container {
    display: flex;
    justify-content: center;
    opacity: 1;
    animation: slideInDown 1s forwards;
}

.logo-home {
    margin-top: 5vh;
    max-width: 20vw;
}

.fondo-home {
    background-size: 100% 100%;
    position: absolute;
}

.explanation-container {
    display: flex;
    text-align: justify;
    margin: auto;
    max-width: 60vw;

    animation: fadeIn 1s forwards;
}

.explanation-text {
    margin-top: 3vh;
    font-size: 2vw;
    color: var(--ion-color-primary);
    text-align: justify;
}

.collaborators-container {
    display: flex;
    text-align: justify;
    margin: auto;
    max-width: 60vw;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
    opacity: 0;
}

.collaborators-text {
    margin-top: 8vh;
    font-size: 1.5vw;
    color: var(--ion-color-primary);
    text-align: justify;
}

.logos-container {
    display: flex;
    justify-content: center;
    margin: auto;
    align-items: center;
    animation: fadeIn 1s forwards;
    animation-delay: 2s;
    opacity: 0;
}

.logo-collaborator {
    max-width: 20vw;
    align-items: center;
}

.logo-collaborator2 {
    max-width: 60vw;
    align-items: center;
}


.slideInDown {
    animation-name: slideInDown;
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes slideInDown {
    0% {
        transform: translateY(-100%);
        visibility: visible;
    }
    100% {
        transform: translateY(0);
    }
}

.fadeIn {
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}



